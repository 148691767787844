<template>
  <div>
    <div
      class="row"
      v-show="!isLoading.modules && modules.length === 0"
    >
      <div class="flex xs12">
        <h5>{{ $t('regions.tabs.modules.nothing_to_add') }}</h5>
      </div>
    </div>
    <div class="row">
      <div
        class="flex xs12 sm6 md4"
        v-for="mod in modules"
        :key="mod.id"
      >
        <va-checkbox
          :label="$t(mod.name)"
          :disabled="loading || isLoading.modules"
          v-model="mod.inRegion"
          @input="updateStatus(mod.id, mod.inRegion)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'change-module',
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    region: {
      type: Object,
      required: false,
      default: function () { return {} },
    },
  },
  data () {
    return {
      isLoading: {
        modules: false,
      },
      modules: [],
      regionModules: [],
    }
  },
  watch: {
    async region (val) {
      console.log(val)
      await this.getModulesOnRegion()
      if (val.modules && this.modules.length !== 0) {
        this.modules = this.fixData(this.modules)
      }
    },
  },
  created () {
    this.getModules()
    if (this.region.id) {
      this.getModulesOnRegion()
    }
  },
  methods: {
    fixData (data) {
      const modules = []
      for (const d of data) {
        const mod = {
          id: d.id,
          name: d.name,
          inRegion: false,
        }
        mod.inRegion = this.moduleIn(d.id)
        modules.push(mod)
        console.log(mod)
      }
      // console.log(modules)
      return modules
    },
    moduleIn (moduleId) {
      console.log(this.regionModules)
      if (this.regionModules.length === 0) return false
      console.log(moduleId)
      const isIn = this.regionModules.find(g => g.module_id === moduleId)
      console.log(isIn)
      return isIn !== undefined
    },
    async getModules () {
      this.isLoading.modules = true

      let u = false
      try {
        u = await this.$http.get('regions/modules/availables')
      } catch (err) {
        console.log('Error fetching region data', err)
        this.isLoading.modules = false
        return
      }

      this.isLoading.modules = false
      this.modules = this.fixData(u.data.data)
    },
    async getModulesOnRegion () {
      this.isLoading.modules = true

      let r = false
      const regionId = this.region.id
      try {
        r = await this.$http.get(`regions/modules?region=${regionId}`)
      } catch (err) {
        // console.log('Error fetching region data', err)
        this.isLoading.modules = false
        return
      }

      this.isLoading.modules = false
      this.regionModules = r.data.data
      this.modules = this.fixData(this.modules)
    },
    async updateStatus (id, inRegion) {
      inRegion ? this.submitNew(id) : this.submitDelete(id)
    },
    async submitNew (moduleId) {
      this.isLoading.modules = true
      const regionId = this.region.id
      const data = {
        region_id: regionId,
        module_id: moduleId,
      }

      let response = null
      try {
        response = await this.$http.post('regions/modules', data)
      } catch (err) {
        // console.log('Error fetching region data', err)
        this.isLoading.modules = false
        return
      }

      response = response.data.data
      const d = { ...response, module_id: moduleId }

      this.regionModules.push(d)
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.isLoading.modules = false
    },
    async submitDelete (moduleId) {
      this.isLoading.modules = true
      const mod = this.regionModules.find(x => x.module_id === moduleId)

      try {
        await this.$http.delete(`regions/modules/${mod.id}`)
      } catch (err) {
        // console.log('Error fetching region data', err)
        this.isLoading.modules = false
        return
      }

      this.regionModules = this.regionModules.filter(x => x.id !== mod.id)
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.isLoading.modules = false
    },
  },
}
</script>
